<template>
  <section
    id="orderList"
    class="d-flex justify-center">
    <div class="sketch-list-content-box">
      <img-popup-preview
        v-model="imgPopup"
        :img-src="currentPreviewImg" />
      <header-tool-bar-old
        :value="filter"
        state="order"
        @input="filterUpdated($event)"
        @search-clicked="filterUpdated($event)" />
      <v-row class="content-row">
        <v-col cols="12">
          <h1 class="title">
            Order Form
          </h1>
        </v-col>
        <v-col cols="7">
          <div class="table-wrap">
            <v-data-table
              v-model="orderSelected"
              :headers="orderHeader"
              :items="orderList"
              :options.sync="options"
              :items-per-page="options.itemsPerPage"
              :server-items-length="itemsLength"
              :loading="tableLoading"
              item-key="id"
              show-select
              dense
              class="sketch-table"
              hide-default-footer
              @update:sort-by="optionsUpdated()"
              @update:sort-desc="optionsUpdated()">
              <template v-slot:body="{ headers, items, isSelected, select }">
                <table-body-prototype
                  :items="items"
                  :headers="headers"
                  :is-selected="isSelected"
                  :select="select"
                  :search-text="filter.search"
                  :prototype-selected-id="prototypeSelected.id"
                  blank-item="-"
                  @click:row="expandOrder($event)">
                  <template v-slot:[`item.orderId`]="{ item }">
                    {{ item.order.orderId | paddingNumber() }}
                  </template>
                  <template v-slot:[`item.sellPrice`]="{ item }">
                    {{ item.order.sellPrice | showFullPriceFormat() }}
                  </template>
                  <template v-slot:[`item.createdAt`]="{ value }">
                    {{ getDate(value) }}
                  </template>
                  <template v-slot:[`item.order.orderDate`]="{ value }">
                    {{ getDate(value) }}
                  </template>
                  <template v-slot:[`item.order.targetDate`]="{ value }">
                    {{ getDate(value) }}
                  </template>
                  <template v-slot:[`item.diffDays`]="{ item }">
                    {{ getDiff(item) }}
                  </template>
                  <template v-slot:[`item.orderStatus`]="{ item }">
                    <v-icon
                      v-if="item.order.orderStatus === 'incomplete'"
                      color="grey">
                      mdi-radiobox-marked
                    </v-icon>
                    <v-icon
                      v-else-if="item.order.orderStatus === 'completed'"
                      color="blue">
                      mdi-radiobox-marked
                    </v-icon>
                    <v-icon
                      v-else
                      color="grey">
                      mdi-radiobox-blank
                    </v-icon>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          x-small
                          v-on="on">
                          <v-icon small>
                            mdi-dots-horizontal
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          link
                          @click="editOrder(item)">
                          <v-list-item-title>EDIT</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          link
                          @click="tryToDeleteSketch(item.id)">
                          <v-list-item-title>DELETE</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </table-body-prototype>
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col cols="5">
          <div
            v-if="prototypeSelected"
            class="detail-wrap pa-0">
            <!-- <order-detail-box
              :order="prototypeSelected.order"
              @img-click="imgPreview($event)" /> -->
            <detail-box
              :prototype-selected="prototypeSelected"
              @img-click="imgPreview($event)" />
          </div>
        </v-col>
        <v-col
          cols="7">
          <v-row>
            <v-col
              cols="12"
              class="d-flex">
              <v-btn
                :disabled="disabledCreateManufac"
                outlined
                class="mx-2"
                @click="createManyManufacture()">
                {{ `ส่งผลิต (${orderSelected.length})` }}
              </v-btn>
              <div class="select-status-wrap">
                <v-select
                  v-model="statusSelected"
                  :disabled="isDisableBtn"
                  label="Select Status"
                  :items="statusList"
                  hide-details
                  dense
                  outlined />
              </div>
              <v-btn
                :disabled="isDisableBtn || statusSelected === ''"
                outlined
                class="mx-2"
                @click="changeStates(orderSelected, statusSelected)">
                เปลี่ยนสถานะรายการที่เลือก
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                :disabled="isDisableBtn"
                outlined
                class="mx-2"
                @click="calculateMargin()">
                {{ `คำนวณ Margin (${orderSelected.length})` }}
              </v-btn>
              <v-btn
                :disabled="isDisableBtn"
                outlined
                class="mx-2"
                @click="exportPdf()">
                Export Pdf
              </v-btn>
            </v-col>
          </v-row>

          <!-- <v-btn
            :disabled="isDisableBtn"
            outlined
            class="mx-2 "
            @click="exportCsv()">
            Export CSV
          </v-btn> -->
        </v-col>
        <v-col cols="5">
          <v-pagination
            v-model="options.page"
            :total-visible="5"
            :length="pageCount"
            :disabled="tableLoading"
            color="secondary"
            @input="pageUpdated($event)" />
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import PrototypeProviderOld from '@/resources/PrototypeProviderOld'
import PrototypeOrderProviderOld from '@/resources/PrototypeOrderProviderOld'
import { ExportToCsv } from 'export-to-csv'
import ManufacProviderOld from '@/resources/ManufacProviderOld'
import HeaderToolBarOld from '../components/HeaderToolBarOld.vue'
import ImgPopupPreview from '../components/ImgModalPreview.vue'
import TableBodyPrototype from '../components/TableBodyPrototype.vue'
import DetailBox from '../components/DetailBox.vue'

const PrototypeService = new PrototypeProviderOld()
const PrototypeOrderService = new PrototypeOrderProviderOld()
const ManufacService = new ManufacProviderOld()

export default {
  components: {
    HeaderToolBarOld,
    ImgPopupPreview,
    TableBodyPrototype,
    DetailBox
  },
  data () {
    return {
      imgPopup: false,
      currentPreviewImg: '',
      statusSelected: '',
      statusList: [
        { header: 'States' },
        { text: 'Sketch', value: 'sketch' }
      ],
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 15
      },
      tableFooterProps: {
        disablePagination: true,
        itemsPerPageOptions: [5, 10, 15, 20, 25, { text: 'All', value: -1 }]
      },
      pageCount: 0,
      itemsLength: 0,
      currentPage: 1,
      prototypeSelected: {},
      orderSelected: [],
      orderHeader: [
        {
          text: 'Sketch ID',
          sortable: false,
          align: 'center',
          value: 'sketch.sketchId'
        },
        {
          text: 'Order ID',
          sortable: false,
          align: 'center',
          value: 'orderId',
          width: 100
        },
        {
          text: 'Product ID',
          sortable: false,
          align: 'center',
          value: 'order.productId',
          width: 100
        },
        {
          text: 'Product Factory ID',
          sortable: false,
          align: 'center',
          value: 'order.productFactoryId',
          width: 175
        },
        {
          text: 'Price',
          sortable: false,
          align: 'center',
          value: 'sellPrice'
        },
        {
          text: 'Collection',
          sortable: false,
          align: 'center',
          value: 'sketch.collectionName'
        },
        {
          text: 'Factory Name',
          sortable: false,
          align: 'center',
          value: 'order.factory.name',
          width: 175
        },
        {
          text: 'Designer',
          sortable: false,
          align: 'center',
          value: 'sketch.designer.name'
        },
        {
          text: 'Merchandiser',
          sortable: false,
          align: 'center',
          value: 'sketch.merchandiser.name'
        },
        {
          text: 'Classification',
          sortable: false,
          align: 'center',
          value: 'sketch.classification'
        },
        {
          text: 'Order Date',
          sortable: false,
          align: 'center',
          value: 'order.orderDate'
        },
        {
          text: 'Target Date',
          sortable: false,
          align: 'center',
          value: 'order.targetDate'
        },
        {
          text: 'Days',
          sortable: false,
          align: 'center',
          value: 'diffDays'
        },
        {
          text: 'Status',
          sortable: false,
          align: 'center',
          value: 'orderStatus'
        },
        {
          text: '',
          sortable: false,
          align: 'center',
          value: 'action'
        }
      ],
      orderList: [],
      prototypes: [],
      tableLoading: false,
      searchTimeout: null
    }
  },
  computed: {
    disabledCreateManufac () {
      const hasDraft = this.orderSelected.findIndex((each) => each.orderStatus === 'draft') !== -1
      const disabled = hasDraft || this.orderSelected.length === 0
      return disabled
    },
    isDisableBtn () {
      return this.orderSelected.length < 1
    },
    filter () {
      const { query } = this.$route
      this.setPage(query?.page || 1)
      return {
        search: query?.search || '',
        designer: query?.designer || '',
        merchandiser: query?.merchandiser || '',
        factory: query?.factory || '',
        collection: query?.collection || '',
        itemsPerPage: Number(query?.itemsPerPage || 15),
        state: 'sketch'
      }
    }
  },
  created () {
    this.getOrders()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setPrototypes: 'Prototypes/setPrototypes',
      setOrders: 'Prototypes/setOrders'
    }),
    isNewSchema (item) {
      return 'colors' in item.order
    },
    setPage (page) {
      this.options.page = Number(page)
    },
    getDate (value) {
      if (value !== '-') {
        return this.$dayjs(value).format('DD/MM/YYYY')
      }
      return value
    },
    getDiff (item) {
      if (item?.order?.orderDate) {
        return this.$dayjs().diff(this.$dayjs(item.orderDate), 'day') + 1
      }
      return '-'
    },
    expandOrder ({ item }) {
      this.prototypeSelected = item
    },
    filterUpdated (filters) {
      this.options.itemsPerPage = filters.itemsPerPage
      this.getDataFromFilters({ ...filters, page: 1 })
    },
    pageUpdated (page) {
      this.getDataFromFilters({ ...this.filter, page })
    },
    getDataFromFilters (filters) {
      this.$router.push({
        name: this.$route.name,
        query: { ...filters }
      }).catch(() => {})
      this.getOrders(this.options)
    },
    optionsUpdated () {
      this.getOrders()
    },
    async getOrders (options = this.options, filter = this.filter) {
      try {
        this.tableLoading = true

        const { data } = await PrototypeOrderService.getManyOrder({ ...options, ...filter })

        this.itemsLength = data.total
        this.pageCount = data.pages
        this.orderList = data.results

        if (this.orderList.length > 0) {
          this.prototypeSelected = this.orderList[0]
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.tableLoading = false
      }
    },
    async changeStates (orders, state) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'CHANGING STATE...'
        })

        const ids = orders.map((order) => order.id)

        await PrototypeService.changeState(ids, state)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.setSnackbar({
          value: true,
          message: 'status has been changed',
          type: 'success'
        })
        this.selectAndPushPath(state)
      }
    },
    async createManyManufacture () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'LOADING...'
        })

        if (this.orderSelected.some((prototype) => !prototype.order.productId)) {
          throw Error('Please update the order before send to manufacture.')
        }

        const ids = this.orderSelected.map((order) => order.id)
        await ManufacService.createManufacturing(ids)

        this.setSnackbar({
          value: true,
          message: 'Create manufacture success',
          type: 'success'
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.$router.push({ name: 'ManufactureListOld' })
      }
    },
    async searchClicked (filter) {
      this.filter = filter
      this.getOrders()
    },
    tryToDeleteSketch (id) {
      this.setModal({
        value: true,
        title: 'DeleteOrder',
        message: 'Do you want to delete this order?',
        confirmText: 'Delete',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.deleteOrder(id)
      })
    },
    async deleteOrder (id) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING SKETCHES...'
        })

        await PrototypeOrderService.deleteOrderById(id)
        this.getOrders()

        this.setSnackbar({
          value: true,
          message: 'order has been deleted',
          type: 'success'
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    selectAndPushPath (state) {
      if (state === 'sketch') {
        this.$router.push({ name: 'SketchListOld' })
      } else if (state === 'order') {
        this.$router.push({ name: 'OrderFormListOld' })
      }
    },
    imgPreview (src) {
      this.currentPreviewImg = src
      this.imgPopup = true
    },
    exportPdf () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'EXPORTING...'
        })

        this.setPrototypes(this.orderSelected)
        this.setLoading({ active: false })
        window.open(this.$router.resolve({ name: 'ExportManufacture' }).href, '_blank')
      } catch (error) {
        console.error('exportPdf', error)
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    calculateMargin () {
      this.setOrders(this.orderSelected)
      this.$router.push({ name: 'MarginCalculate' })
    },
    exportCsv () {
      if (this.orderSelected.length > 0) {
        const preExport = this.orderSelected.map((order) => ({
          'Sketch ID': order.sketch?.sketchId || '-',
          'Order ID': order?.orderId || '-',
          'Product ID': order?.productId || '-',
          'Product Factory ID': order?.productFactoryId || '-',
          'Category': order.sketch?.category?.name || '-',
          'Collection': order.sketch?.collectionName || '-',
          'Designer': order.sketch?.designer?.name || '-',
          'Merchandiser': order.sketch?.merchandiser?.name || '-',
          'Factory': order?.factory?.name || '-',
          'Classification': order?.sketch?.classification || '-',
          'Date': this.$dayjs(order?.createdAt).format('YYYY-MM-DD') || '-',
          'OrderDate': this.$dayjs(order?.orderDate).format('YYYY-MM-DD') || '-',
          'TargetDate': this.$dayjs(order?.targetDate).format('YYYY-MM-DD') || '-',
          'สถานะ': order?.orderStatus || '-',
          'โน๊ต': order.sketch?.note || '-',
          'Sizing Detail': this.preExportSizingDetail(order.sizeDetail),
          'Product SKUS': this.preExportProductSkus(order.productSkus),
          'ราคาขาย': order?.sellPrice || '-',
          'ราคาผ้า': order?.clothesCostPerMetr || '-',
          'ราคาผลิต': (order?.manufacCountry === 'thailand' ? order?.manufacCost : order?.manufacCost * 5) || '-',
          'โรงงาน': order?.manufacCountry || '-'
        }))

        const options = {
          filename: `MANUFACTURES(${this.$dayjs().format('YYYY-MM-DDTHH-mm')})`,
          showLabels: true,
          useKeysAsHeaders: true
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(preExport)
      }
    },
    editOrder (item) {
      if (item.sketch.category) {
        this.$router.push(`/prototype/order/edit-old/${item.id}`)
      } else {
        this.setSnackbar({
          value: true,
          message: 'Please fill category / type in sketch before edit order.',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style>
.sketch-table .v-data-table__wrapper, table {
  height: 100% !important
}
</style>

<style scoped>
.v-data-table--dense.sketch-table > .v-data-table__wrapper > table > tbody > tr > td {
  cursor: pointer;
}
.sketch-table .sketch-table-col {
  height: 32px !important;
  cursor: pointer;
}
.sketch-table .sketch-table-header {
  text-align: center;
  font-weight: bold;
  color: black !important;
}

.sketch-table.sketch-table-row {
  cursor: pointer;
}

.sketch-list-content-box {
  position: relative;
  padding: 15px 15px;
  /* background-color: #fff; */
  width: 100%;
  /* max-width: 1375px; */
}

.content-row {
  position: relative;
  padding: 10px 0;
  background-color: #fff;
}

.table-wrap {
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: fit-content;
}

.detail-wrap {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  height: 532px;
  overflow-y: scroll;
}

.action-col {
  color: rgb(83, 83, 240);
  cursor: pointer;
}

.field-label {
  font-weight: bold;
}

.sketch-image {
  margin: 4px 12px 12px;
  height: 120px;
}

.sketch-table {
  height: 100%
}
</style>
